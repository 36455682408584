import React, { useState } from "react";
import { Alert, Avatar, Box, Button, Chip, Divider, Modal, TextField, Typography } from "@mui/material";
import BreadcrumComp from "../../../components/ui/BreadcrumComp";
import { useParams } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import styled from "@emotion/styled";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import btalogo from "../../../imgs/bta-logo.png";
import ContentBox from "../../../components/ui/ContentBox";
import { useEffect } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import { ToastContainer, toast } from "react-toastify";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as yup from "yup";
import FormikErrorText from "../../../components/ui/FormikErrorText";
import ButtonSquare from "../../../components/ui/ButtonSquare";
import { modalStyleOverflow } from "../../../theme/ModalStyle";
function PurchaseDetails() {
  const [total, setTotal] = useState(0);
  const [openSetPaid, setOpenPaid] = useState({ open: false, data: null });
  const initialValuesPaid = {
    discount: 0,
  };
  const validationSchemaPaid = yup.object({
    discount: yup?.number("Invalid value").min(0, "invalid value").required("This field is required"),
  });
  useEffect(() => {
    document.title = "BTA admin - Store / Purchase details";
  }, []);
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "white",
      paddingTop: "8px",
      paddingBottom: "8px",
      letterSpacing: "1px",
      color: `#4B465C`,
      fontSize: 13,
      fontWeight: 500,
    },
    [`&.${tableCellClasses.body}`]: {
      background: "white",
      fontSize: 14,
      color: `#666D83`,
      fontWeight: "500",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    ":hover": {
      filter: "brightness(0.95)",
    },
    cursor: "pointer",
  }));

  const StyledTable = styled(Table)({
    borderRadius: "0px",
  });
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [data, setData] = useState([]);
  const { id } = useParams();
  const [error, setError] = useState("");
  const [change, setChange] = useState(false);
  useEffect(() => {
    Api.get(Urls.GET_ORDERS + "/" + id, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setData(res?.data?.data);
        let addition = parseFloat(res?.data?.data?.discount) + parseFloat(res?.data?.data?.subtotal);
        setTotal(addition);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }, [change]);
  const markAsPayed = (values) => {
    setError("");
    Api.patch(
      Urls.PATCH_ORDER + id,
      {
        status: "PAID",
        discount: values?.discount,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast?.success(res?.data?.message);
        setChange(!change);
        setOpenPaid({ open: false, data: null });
      })
      .catch((err) => {
        setError(err?.response?.data?.message);
      });
  };
  console.log(total, "total");
  const markAsCanceled = () => {
    Api.patch(
      Urls.PATCH_ORDER + id,
      {
        status: "CANCELED",
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast?.success(res?.data?.message);
        setChange(!change);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  return (
    <Box display="flex" flexDirection="column" gap="26px" marginTop="26px">
      <Box>
        <ToastContainer />
        <BreadcrumComp first="Store" second="Purchases" third={`Invoice #${id}`} />
      </Box>
      <Box display="flex" flexDirection="row" flexWrap="wrap" gap="16px" width="100%">
        <Box sx={{ flex: 3 }}>
          <ContentBox>
            <Box display="flex" flexDirection="column" gap="40px">
              <Box display="flex" justifyContent="space-between" flexWrap="wrap">
                <Box display="flex" flexDirection="column" gap="24px">
                  <Box display="flex" flexDirection="row" alignItems="center" gap="6px">
                    <Avatar src={btalogo} />
                    <Box>
                      <Typography fontSize="22px" fontWeight="600" color="#4B465C" sx={{ pointer: "cursor", userSelect: "none" }}>
                        BTA
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Typography fontSize="15px" fontWeight="400" lineHeight="22px" color="#4B465C">
                      Bahrein Tenis Academy.
                    </Typography>
                    <Typography fontSize="15px" fontWeight="400" lineHeight="22px" color="#4B465C">
                      Adresse : Road 7115, Saar, Bahreïn
                    </Typography>
                    <Typography fontSize="15px" fontWeight="400" lineHeight="22px" color="#4B465C">
                      Téléphone : +973 3969 7114
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" gap="12px">
                  <Box display="flex" alignItems="center" gap="10px">
                    <Typography fontSize="22px" fontWeight="500" lineHeight="30px" color="#4B465C">
                      Invoice #{id}
                      {"   "}
                    </Typography>
                    <Box>
                      {(data?.status === "PAID" || data?.status === "CANCELED") && <Chip label={data?.status === "PAID" ? "PAID" : "CANCELED"} sx={{ backgroundColor: `${data?.status === "PAID" ? "rgba(40, 199, 111, 0.16)" : "rgba(234, 84, 85, 0.16)"}`, color: `${data?.status === "PAID" ? "rgba(40, 199, 111, 1)" : "rgba(234, 84, 85, 1)"}`, borderRadius: "8px" }} />}
                      {data?.status === "PENDING" && <Chip label={"PENDING"} sx={{ backgroundColor: "rgba(255, 213, 128 , 0.5)", color: "orange", borderRadius: "8px" }} />}
                    </Box>
                  </Box>
                  <Typography fontSize="15px" fontWeight="500" lineHeight="22px" color="#4B465C">
                    Date Issues: {data?.created_at?.split(" ")[0]}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Divider sx={{ background: "#DBDADE" }} />
              </Box>
              <Box display="flex" gap="24px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
                <Box flex={1} display="flex" flexDirection="column" gap="24px">
                  <Box>
                    <Typography fontSize="15px" fontWeight="500" lineHeight="22px" color="#4B465C">
                      Invoice To:
                    </Typography>
                  </Box>
                  <Box>
                    <Typography fontSize="15px" fontWeight="400" lineHeight="22px" color="#4B465C">
                      {data?.user_first_name} {data?.user_last_name}
                    </Typography>
                    <Typography fontSize="15px" fontWeight="400" lineHeight="22px" color="#4B465C"></Typography>
                    <Typography fontSize="15px" fontWeight="400" lineHeight="22px" color="#4B465C">
                      {data?.user_email}
                    </Typography>
                  </Box>
                </Box>
                <Box flex={1} display="flex" flexDirection="column" gap="24px">
                  <Box>
                    <Typography fontSize="15px" fontWeight="500" lineHeight="22px" color="#4B465C">
                      Bill To:
                    </Typography>
                  </Box>
                  <Box>
                    <Box display="flex">
                      <Typography flex={1} fontSize="15px" fontWeight="400" lineHeight="22px" color="#4B465C">
                        Total Due:
                      </Typography>
                      <Typography flex={1} fontSize="15px" fontWeight="400" lineHeight="22px" color="#4B465C">
                        {parseFloat(data?.total).toLocaleString("fr-FR")} BHD
                      </Typography>
                    </Box>
                    <Box display="flex">
                      <Typography flex={1} fontSize="15px" fontWeight="400" lineHeight="22px" color="#4B465C">
                        Payment method:
                      </Typography>
                      <Typography flex={1} fontSize="15px" fontWeight="400" lineHeight="22px" color="#4B465C">
                        Credit card
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                <TableContainer sx={{ borderRadius: "0px", border: `1px solid #DBDADE` }}>
                  <StyledTable aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell width="30%" align="left">
                          ITEM
                        </StyledTableCell>
                        <StyledTableCell width="30%" align="left">
                          DESCRIPTION
                        </StyledTableCell>{" "}
                        <StyledTableCell width="15%" align="left">
                          COST
                        </StyledTableCell>{" "}
                        <StyledTableCell width="10%" align="left">
                          QTY
                        </StyledTableCell>{" "}
                        <StyledTableCell width="15%" align="left">
                          PRICE
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.items?.map((product) => (
                        <StyledTableRow>
                          {product?.is_product && <StyledTableCell align="left">{product?.name}</StyledTableCell>}
                          {product?.is_program && (
                            <StyledTableCell align="left">
                              {product?.program_name} - ({product?.program_duration}min) - {product?.player_first_name} {product?.player_last_name}
                            </StyledTableCell>
                          )}
                          {product?.is_private_lesson && (
                            <StyledTableCell align="left">
                              PRIVATE LESSON - ({product?.private_lesson_duration}min) - {product?.player_first_name} {product?.player_last_name}
                            </StyledTableCell>
                          )}
                          {product?.is_product && <StyledTableCell align="left">-</StyledTableCell>}
                          {product?.is_program && (
                            <StyledTableCell align="left">
                              <Box display="flex" flexDirection="column">
                                <Box>
                                  {product?.program_duration_frequency_per_week} {product?.program_duration_frequency_per_week > 1 ? "slots" : "slot"} per week
                                </Box>
                                <Box>Subscription at : {product?.start_date?.split("T")[0]}</Box>
                              </Box>
                            </StyledTableCell>
                          )}
                          {product?.is_private_lesson && (
                            <StyledTableCell align="left">
                              <Box display="flex" flexDirection="column">
                                <Box>
                                  {product?.private_lesson_nb_players} {product.private_lesson_nb_players > 1 ? "Players" : "Player"} / {product?.private_lesson_nb_lessons} {product?.private_lesson_nb_lessons > 1 ? "Lessons" : "Lesson"}
                                </Box>
                                <Box>Subscription at : {product?.start_date?.split("T")[0]}</Box>
                              </Box>
                            </StyledTableCell>
                          )}
                          <StyledTableCell align="left">{parseFloat(product?.unit_price).toLocaleString("fr-FR")} BHD</StyledTableCell>
                          <StyledTableCell align="left">{product?.quantity}</StyledTableCell>
                          <StyledTableCell align="left">{parseFloat(product?.total).toLocaleString("fr-FR")} BHD</StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </StyledTable>
                </TableContainer>
              </Box>
              <Box display="flex" justifyContent="flex-end" minWidth="300px">
                <Box display="flex" flexDirection="column" gap="8px" minWidth="300px">
                  <Box display="flex">
                    <Typography flex={1} fontSize="15px" fontWeight="400" lineHeight="22px" color="#4B465C">
                      Subtotal:
                    </Typography>
                    <Typography flex={1} fontSize="15px" fontWeight="400" lineHeight="22px" color="#4B465C">
                      {parseFloat(total).toLocaleString("fr-FR")} BHD
                    </Typography>
                  </Box>
                  <Box display="flex">
                    <Typography flex={1} fontSize="15px" fontWeight="400" lineHeight="22px" color="#4B465C">
                      Discount:
                    </Typography>
                    <Typography flex={1} fontSize="15px" fontWeight="400" lineHeight="22px" color={data?.discount > 0 ? "red" : "#4B465C"}>
                      {parseFloat(data?.discount).toLocaleString("fr-FR")} BHD
                    </Typography>
                  </Box>
                  <Box display="flex">
                    <Typography flex={1} fontSize="15px" fontWeight="400" lineHeight="22px" color="#4B465C">
                      Tax:
                    </Typography>
                    <Typography flex={1} fontSize="15px" fontWeight="400" lineHeight="22px" color="#4B465C">
                      {parseFloat(data?.vat).toLocaleString("fr-FR")} BHD
                    </Typography>
                  </Box>
                  <Box display="flex">
                    <Typography flex={1} fontSize="15px" fontWeight="400" lineHeight="22px" color="#4B465C">
                      Total:
                    </Typography>
                    <Typography flex={1} fontSize="15px" fontWeight="400" lineHeight="22px" color="#4B465C">
                      {parseFloat(data?.total).toLocaleString("fr-FR")} BHD
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </ContentBox>
        </Box>
        <Box flex={1}>
          <ContentBox>
            <Box display="flex" flexDirection="column" gap="16px">
              <Button onClick={() => setOpenPaid({ open: true, data: id })} disabled={data?.status === "PAID" ? true : false} variant="primary_active">
                Mark as PAID
              </Button>
              <Button onClick={markAsCanceled} disabled={data?.status === "CANCELED" || data?.status === "PAID" ? true : false} variant="primary_inactive">
                Mark as CANCELED
              </Button>
            </Box>
          </ContentBox>
        </Box>
      </Box>
      <Modal open={openSetPaid?.open} onClose={() => setOpenPaid({ open: false, data: null })}>
        <Box sx={modalStyleOverflow}>
          <Box position="absolute" top="2px" right="2px">
            <ButtonSquare action="close" onClick={() => setOpenPaid({ open: false, data: null })} />
          </Box>
          <Box>
            <Formik onSubmit={(values) => markAsPayed(values)} validationSchema={validationSchemaPaid} initialValues={initialValuesPaid} enableReinitialize={true}>
              {({ errors, setFieldValue, values }) => (
                <Form>
                  <Box marginBottom="26px">
                    <Typography textAlign="center" fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
                      Mark as paid
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="column" gap="16px">
                    <Box display="flex" sx={{ gap: { xs: "8px", sm: "8px", md: "16px", lg: "26px" } }}>
                      <Box flex={1}>
                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                          Discount
                        </Typography>
                        <Field style={{ width: "100%" }} name="discount" type="number" id="level_name" as={TextField} autoComplete="off" placeholder="Discount..." />
                        <ErrorMessage component={FormikErrorText} name="discount" />
                      </Box>
                    </Box>
                    {error && <Alert severity="error">{error}</Alert>}
                    <Box marginTop="10px" display="flex" gap="16px">
                      <Button type="submit" variant="primary_active">
                        Mark as PAID
                      </Button>
                      <Button onClick={() => setOpenPaid({ open: false, data: null })} variant="primary_inactive">
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default PurchaseDetails;
